<template>
  <vca-card class="shadowed">
    <vca-field :label="$t('taking.log.title')">
      <div
        :key="index"
        v-for="(res, index) in value.activities.slice().reverse()"
      >
        <vca-card>
          <vca-row>
            <h3 first>
              {{ res.user.full_name }} ({{ datetime(res.modified.created) }})
            </h3>
            <div>{{ res.comment }}</div>
            <h3 last class="activity_status">
              {{ $t("taking.log.state." + res.status) }}
            </h3>
          </vca-row>
        </vca-card>
      </div>
    </vca-field>
  </vca-card>
</template>
<script>
export default {
  name: "TakingEditActivity",
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>
<style lang="scss">
.activity_status {
  text-align: right;
}
</style>
